<template>
	<div id="newsletter">
		<div class="main-page-wrapper font-gordita">
			<!-- ===================================================
				Loading Transition
			==================================================== -->
			<section>
				<div id="preloader">
					<div id="ctn-preloader" class="ctn-preloader">
						<div class="animation-preloader">
							<div class="text-center">
								<img class="spinner" src="../../../assets/image/ingomu-preloader.gif" alt="" />
							</div>
							<!-- <div class="spinner"></div> -->
							<div class="txt-loading">
								<span data-text-preloader="i" class="letters-loading">
									i
								</span>
								<span data-text-preloader="n" class="letters-loading">
									n
								</span>
								<span data-text-preloader="g" class="letters-loading-green">
									g
								</span>
								<span data-text-preloader="o" class="letters-loading-green">
									o
								</span>
								<span data-text-preloader="m" class="letters-loading">
									m
								</span>
								<span data-text-preloader="u" class="letters-loading">
									u
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>


			<!-- 
			=============================================
				Theme Main Menu
			============================================== 
			-->
			<SiteHeader></SiteHeader>

			<!-- 
			=============================================
				Theme Hero Banner
			============================================== 
			-->
			<div class="hero-banner-two pb-0">
				<div class="container">
					<div class="row align-items-start justify-content-between">
						<div class="col-lg-5 order-lg-last">
							<div class="illustration-holder">
								<img src="../../../assets/v2/images/assets/Ingomu Business Newsletter_v1.png" alt="" class="illustration_01 br-25">
								<img src="../../../assets/v2/images/assets/ingomu_testimonial_1_v3.png" alt="" class="shapes shape-one bs-img">
								<img src="../../../assets/v2/images/assets/ingomu_testimonial_2_v3.png" alt="" class="shapes shape-two bs-img">
								<img src="../../../assets/v2/images/assets/ingomu_testimonial_3_v3.png" alt="" class="shapes shape-three bs-img">
							</div>
						</div>

						<div class="col-lg-7 order-lg-first">
							<div class="hero-text-wrapper md-mt-50">
								<h2 class="lh-13 fs-50 fw-600">
									Exclusive Coaching Tips for Busy HR Leaders Directly to Your Inbox!
								</h2>
								<p class="sub-text">Get step-by-step strategies to build employee coaching systems that help you attract, develop, and retain top talent.</p>
								<form v-on:submit.prevent="submit('newsletter')" data-vv-scope="newsletter">
									<input type="email" class="required email" v-model="newsletterForm.email" name="actionemail"
                                    :class="{ 'border-red': errors.has('newsletter.email') }"
                                    placeholder="Your Email Address" v-validate="'required|email'"
                                    id="actionemail" required="required" data-error="A valid email is required." />
									<button>Get Started</button>
								</form>
								<ul class="list-item d-flex mt-20">
									<li>No buzzwords and gimmicks, just strategy with practical advice that delivers results.</li>
									<!-- <li>Ticketing</li>
									<li>14 days free trial</li> -->
								</ul>
							</div> 
						</div>
					</div> <!-- /.row -->
				</div> <!-- /.container -->
			</div>
			 <!-- /.hero-banner-two -->

			 			<!--
			=====================================================
				Client Feedback Slider One
			=====================================================
			-->
			<div class="client-feedback-slider-one pb-100 md-pb-80 mt-80 md-mt-80">
				<div class="shapes-holder" data-aos="fade-up" data-aos-duration="1200">
					<img src="../../../assets/v2/images/shape/39.svg" alt="">
					<img src="../../../assets/v2/images/shape/42.svg" alt="" class="shapes shape-one">
					<img src="../../../assets/v2/images/assets/Ellie_S.png" alt="" class="cp-img-one">
					<img src="../../../assets/v2/images/assets/Christopher_W.png" alt="" class="cp-img-two">
					<img src="../../../assets/v2/images/assets/Suzanne_M.png" alt="" class="cp-img-three">
					<img src="../../../assets/v2/images/assets/Carolyn_D.png" alt="" class="cp-img-four">
					<div class="title-style-two">
						<h2>What <br>Our Users Say <br>About Us.</h2>
					</div>
				</div> <!-- /.shapes-holder -->
				<div class="container">
					<div class="row" data-aos="fade-up" data-aos-duration="1200">
						<div class="col-sm-12 col-md-12 col-lg-6 ml-auto md-mr-auto">
							<div class="feedback-meta">
								<img src="../../../assets/v2/images/icon/30.svg" alt="" class="icon">
								<div class="clientSliderOne">
									<div class="item">
										<p class="font-gordita">I can't believe how energized I still feel after being part of Coach Sullivan's coaching session! Learning about turning mediocre teams into high performing ones was an absolute game-changer. The way she coached wasn't only packed with valuable insights, but the whole experience was like a burst of positivity and enthusiasm.</p>
										<div class="d-lg-flex align-items-center">
											<img src="../../../assets/v2/images/assets/Ellie_S.png" alt="" class="c_img">
											<div class="info">
												<strong>Ellie S.</strong>
											</div>
										</div>
									</div>
									<div class="item">
										<p class="font-gordita">Life can be difficult to navigate for everyone. Life coaching is tremendously helpful for overcoming the challenges we all face, but historically it’s only been affordable for the well-off. Ingomu changes the game. It brings amazing coaches, covering a broad range of life, work, health, and spiritual topics, to the masses, at an incredibly reasonable price.</p>
										<div class="d-lg-flex align-items-center">
											<img src="../../../assets/v2/images/assets/Christopher_W.png" alt="" class="c_img">
											<div class="info">
												<strong>Chris W.</strong>
											</div>
										</div>
									</div>
									<div class="item">
										<p class="font-gordita">Anytime I'm working through an issue in life or am stuck, I can go in and search on the Ingomu to find some instant advice and solutions. Things I never would have thought of. I really lean on Theresa's boundary sessions. It's normally just what I need to feel empowered again and take on life. Really grateful that Ingomu is here!!  Thank you. It's like having dozens of amazing Coaches available to me all the time. Wow.</p>
										<div class="d-lg-flex align-items-center">
											<img src="../../../assets/v2/images/assets/Suzanne_M.png" alt="" class="c_img">
											<div class="info">
												<strong>Suzanne M.</strong>
											</div>
										</div>
									</div>
									<div class="item">
										<p class="font-gordita">As a coach myself, I find so much value in many of the coaching communities that Ingomu offers. I love the interaction of live sessions but also the convenience of listening to sessions at my own pace since recordings of live sessions are posted. This is a really unique app because it addresses the many facets of who I am as parent, leader, entrepreneur, and human through the various coaching areas.</p>
										<div class="d-lg-flex align-items-center">
											<img src="../../../assets/v2/images/assets/Carolyn_D.png" alt="" class="c_img">
											<div class="info">
												<strong>Carolyn S.</strong>
											</div>
										</div>
									</div>
								</div>
								<ul class="d-flex slider-arrow mt-40">
									<li class="prev_c"><i class="flaticon-right-arrow"></i></li>
									<li class="next_c"><i class="flaticon-right-arrow"></i></li>
								</ul>
							</div> <!-- /.feedback-meta -->
						</div>
					</div>
				</div> <!-- /.container -->
			</div> <!-- /.client-feedback-slider-one -->


			<SiteFooter></SiteFooter>

						<!-- Modal Contact Form One -->
						<div class="modal fade modal-contact-popup-one" id="contactModal" tabindex="-1" role="dialog"
				aria-labelledby="contactModalTitle" aria-hidden="true">
				<ScheduleCall @clicked="closeModal"></ScheduleCall>
			</div>

		</div>
	</div>
</template>

<script>
import postscribe from "postscribe";
import SiteHeader from "@/components/EnterpriseHeader";
import SiteFooter from "@/components/SiteFooter";
import ScheduleCall from "@/components/ScheduleCall";
import { mapActions, mapGetters } from "vuex";

export default {
	name: 'newsletter',
	components: {
		SiteHeader,
		SiteFooter,
		ScheduleCall
	},
	metaInfo() {
		return {
			title: "Employee Coaching Tips for Busy HR Teams Newsletter",
		}
	},
	mounted() {
		if (this.$route.hash) {
			setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
		}
		postscribe(
			"#newsletter",
			`<script src="https://dktoyr513tjgs.cloudfront.net/v2/vendor/mega-menu/assets/js/custom.js"><\/script>`
		);
		postscribe(
			"#newsletter",
			`<script src="https://dktoyr513tjgs.cloudfront.net/v2/js/theme.js?v=3"><\/script>`
		);
	},
	data() {
		return {
			newsletterForm: {
                email: ""
            },
		}
	},
	methods: {
		...mapActions(["newsletterBusiness"]),
		submit(scope) {
            var _this = this;
            this.$validator.validateAll(scope).then(function (res) {
                if (res) {
                    _this
                        .newsletterBusiness({
                            email: _this.newsletterForm.email
                        })
                        .then(() => {
                            _this.newsletterForm.email = "";
                            _this.$validator.reset();
                            _this.$fire({
                                title: "Success",
                                text: "Thank you for subscribing. Welcome to our community! Look for your first Employee Coaching Tips for Busy HR Teams shortly.",
                                type: "success",
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            _this.$fire({
                                title: "Error",
                                text: "Sorry, there seems to be a problem.",
                                type: "error",
                            });
                        });
                }
            });
        },
		closeModal(value) {
			if (value) {
				this.$fire({
					title: "Success",
					text: "Thank you! Our team has received your request. Keep an eye on your inbox; we'll be in touch shortly to schedule our call. Thank you for choosing Ingomu.",
					type: "success",
				});
			}
		},
	}
}
</script>
<style scoped>
.br-25 {
	border-radius: 25px;
}
	.sub-text {
		font-size: 24px !important;
		font-weight: 400 !important;
		line-height: 1.5 !important;
		color: #333 !important;
	}
	.hero-banner-two .hero-text-wrapper form button {
		background: #3b4998 !important;
	}
	.hero-banner-two .hero-text-wrapper form button:hover {background: #46c346 !important;}
	.lh-13 {
		line-height: 1.3 !important;
	}
	.hero-banner-two .illustration-holder .shape-one {
		left: 3% !important;
	}
	.client-feedback-slider-one .clientSliderOne p {
		font-size: 20px;
	}
	.logo-swish {
		display: inline-block;
		position: relative;
	}
	.swish-image {
		position: absolute;
		top: 87%;
	}
	@media (max-width: 1370px) {
		.md-mr-auto {
			margin-right: auto !important;
		}
	}
	@media (max-width: 1370px) {
.hero-banner-two .container {
	padding: 0 25px;
}
}

.fs-50 {
	font-size: 50px !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.pb-0 {
	padding-bottom: 0;
}

.mt-80 {
	margin-top: 80px;
}
</style>